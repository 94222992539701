import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function DataProtectionModal({ open, onClose }) {
  const dataProtectionText = (
    <div>
      <p>
        Thank you for your interest in the Technische Universität Berlin (TU
        Berlin) website. The protection of the personal data of visitors to the
        TU Berlin website is very important to us. We therefore would like to
        inform you about data privacy for websites under *.tu.berlin.
      </p>
      <h3>Subject of data privacy</h3>
      <p>
        Data privacy addresses issues concerning personal data. According to
        Article 4 no. 1 of the General Data Protection Regulation (GDPR), these
        are data referring to an identified or identifiable individual, in other
        words all data which could be used to identify you. This applies to data
        such as your name, private address, email address, and telephone number
        but also to usage data such as your IP address. As a matter of course
        Technische Universität Berlin observes the legal requirements of data
        privacy and other applicable regulations. We are committed to ensuring
        that you can trust us concerning your personal data. For this reason
        transfers of personal or sensitive data are encrypted. In addition, our
        websites are protected by technical measures against damage or
        unauthorized access.
      </p>
      <h3>General data privacy</h3>
      <ol>
        <li>
          When you visit the TU Berlin website or download data from its web
          pages, the following information is saved in a log file:
          <ul>
            <li>IP address of your device,</li>
            <li>the date and time you accessed the site,</li>
            <li>
              your e-mail adress, if you request data or download data to get in
              contact with you
            </li>
            <li>the name of the file you downloaded</li>
            <li>the “user agent-string” of your web browser,</li>
            <li>a status notification of successful or unsuccessful access.</li>
          </ul>
          Non-depersonalized data will be deleted after two weeks and will not
          be stored. This data will only be used for non-commercial purposes: It
          will only be reviewed manually if necessary for error analysis,
          optimization or investigation of misuse or performance issues. This
          represents a legitimate interest within the meaning of Art. 6 (1) lit.
          f EU-DSGVO. A right of objection according to Art. 21 EU-DSGVO is not
          possible due to lack of technical feasibility.
        </li>
        <li>
          In order to protect your transmitted data as best as possible, we use
          SSL encryption for any data transmission. You can recognize such
          encrypted connections by the prefix "https://" in the page link in the
          address line or by the green/closed lock symbol of your browser.
          Unencrypted pages are marked by "http://" or an open or red lock
          symbol. All data that you transmit to this website - for example when
          requesting or downloading data - cannot be read by third parties
          thanks to SSL encryption.
        </li>
        <li>
          Cookies are often additionally used for data collection and storage.
          Cookies are small data packets consisting of text that are stored by
          your web browser when you call up a website. Cookies are used by us to
          enable you to make optimal use of the websites. They are never used to
          track personal user actions or for behavioral analysis. They are only
          used where they are technically necessary or more useful than other
          techniques (in the sense of Art. 6 para. 1 lit. f EU-DSGVO): to record
          your login status, your consent to the display of external content
          (opt-in) and your deactivation of cookie-less Matomo tracking
          (opt-out). As a result, the use of the websites is mainly possible
          without the use of cookies. However, for functions or pages that
          require a login or password, cookies are technically necessary. We use
          temporary and permanent cookies. Temporary cookies (also known as
          session cookies) are automatically deleted when you close your web
          browser. Permanent cookies, on the other hand, have a set lifetime.
          You can set your web browser to delete cookies that have already been
          placed.
        </li>
      </ol>
      <h3>Scope of data collection and storage</h3>
      <p>
        The active communication of your personal data is not required for the
        use of our websites and the access of the published satellite data - in
        order for TU Berlin to fulfill its service mandate, however, we require
        personal data in individual cases, the processing of which is carried
        out in accordance with Art. 6 (1) lit. b EU-DSGVO. This applies in
        particular to answering individual e-mail inquiries or ordering special
        services. If you commission us to provide a service, we will generally
        only collect and store your personal data to the extent necessary for
        the provision of the service. Personal data is only recorded and stored
        if the download of the satellite data is requested via the corresponding
        form. In this case, the e-mail address is stored but not passed on to
        third parties and the data is deleted after a short time.
      </p>
      <strong>
        You can revoke your consent at any time and without giving reasons and
        request the deletion of your data. You can request the latter by sending
        an e-mail to the institution holding the data.
      </strong>
      <h3>Collection and sorage of usage data</h3>
      <h4>Log data</h4>
      <p>
        To optimize our website, we store data such as the IP address of your
        device, the page accessed, the date and time of the page access, the
        page from which you accessed our site. This data is held for error
        handling and to detect misuse attempts, automatically deleted after two
        weeks and not archived; permanent storage is excluded. This represents a
        legitimate interest within the meaning of Art. 6 para. 1 lit. f
        EU-DSGVO. A right of objection according to Art. 21 EU-DSGVO is not
        possible due to lack of technical feasibility.
      </p>
      <h4>Contact forms</h4>
      <p>
        The data you send us via contact form, including your contact details,
        will be stored so that we can process your request or ask follow-up
        questions. This data will not be passed on to third parties without your
        consent. The processing of the data entered in the contact form is based
        exclusively on your consent (Article 6 paragraph 1 lit. a EU-GDPR). A
        revocation of your already given consent is possible at any time. For
        the revocation, an informal notification by e-mail to the institution
        providing the respective contact form is sufficient. The legality of the
        data processing operations carried out until the revocation remains
        unaffected by the revocation. Data transmitted via the contact form will
        remain with the institution contacted until there is no longer any need
        to store the data, you request us to delete it or revoke your consent to
        storage. Mandatory legal provisions - in particular retention periods -
        remain unaffected.
      </p>
      <h4>Open Street Maps</h4>
      <p>
        Some of our web pages use a plugin to integrate maps. For this purpose,
        we use the “OpenStreetMap” (OSM) tool provided by the OpenStreetMap
        Foundation. When visiting a page with the embedded OSM plugin, a
        connection to the OpenStreetMap servers is established. The operators of
        OpenStreetMap receive information about which page with the OSM map you
        are currently viewing as well as your IP address. As the operator of
        https://www.tu.berlin/, TU Berlin does not have any influence on this
        data transmission. We use OpenStreetMap to make our website more
        attractive to users and enable them to more easily find the offices and
        places listed on our web pages. This is lawful under Article 6 paragraph
        1 lit. f EU-GDPR. Specific details about how user data are handled are
        available on{" "}
        <a href="https://osmfoundation.org/wiki/Privacy_Policy">
          OpenStreetMap’s privacy page
        </a>
        .
      </p>
      <h3>Storage location and data usage for a specific purpose</h3>
      <p>
        We collect, process and store your personal data exclusively internally
        (usually in the computer center of the TU Berlin) only for the purpose
        for which you have communicated it to us and thus observe the principle
        of purpose-bound data use. Your personal data will not be passed on to
        third parties without your express consent, unless this is necessary for
        the provision of the service or the execution of the contract. The
        transfer to state institutions and authorities requesting information
        will also only take place within the framework of the legal obligations
        to provide information or if we are obliged to provide information by a
        court decision. We also take the University's internal data protection
        very seriously. Our employees and contracted service providers are bound
        to confidentiality and compliance with data protection law.
      </p>
      <h3>Right to information</h3>
      <p>
        You can obtain information about the data we have stored about you free
        of charge at any time without having to give a reason. For this purpose,
        please contact us at the address given in the imprint. We will be happy
        to answer any further questions you may have about our privacy policy.
        In addition, you have the right to correct, delete, restrict and
        transfer your data. You also have the right to object at any time to the
        processing of personal data concerning you that is carried out on the
        basis of Article 6 paragraph 1 lit. e and f EU-GDPR. Thereafter, the
        institution responsible may no longer process the personal data
        concerning you, unless it can demonstrate compelling legitimate grounds
        for the processing which override your interests, rights and freedoms,
        or the processing serves the purpose of asserting, exercising or
        defending legal claims.
      </p>
      <h4>Right of complaint</h4>
      <p>
        If you recognize any indications that we are not handling your data in a
        data protection-compliant manner, you can contact the Data Protection
        Team at Technische Universität Berlin (info@datenschutz.tu-berlin.de) or
        the supervisory authority at any time.
      </p>
      <p>
        Of course, you also have the right to contact the supervisory authority:
      </p>
      <strong>
        Berlin Commissioner for Data Protection and Freedom of Information </strong>
        <br />
        Alt–Moabit 59–61
        <br />
        10555 Berlin
        <br />
        Phone: +49 30 13889-0
        <br />
        Fax: +49 30 2155050
        <br />
     
      Email: mailbox(at)datenschutz-berlin.de
      <br />
      <h3>Final information</h3>
      <p>
        Please note that data privacy regulations and handling of data privacy
        are subject to change requiring you to regularly inform yourself about
        changes of data privacy laws and company policies.
      </p>
    </div>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            maxHeight: "80vh",
            overflow: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            <h1>Data Privacy Statement</h1>
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {dataProtectionText}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default DataProtectionModal;
