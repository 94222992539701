import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function SiteCreditsModal({open, onClose}) {
    const siteCreditText = (
        <div>
            <p>The web content of Technische Universität Berlin is coordinated and maintained independently by the individual departments, institutes and faculties, etc. If you have questions or comments, please contact the responsible office directly.</p>

            <h2>Contact Persons</h2>
            <h3>Main responsibility</h3>
            <p><strong>Prof. Dr. Geraldine Rauch (President)</strong><br />
            <a href="mailto:pressestelle@tu-berlin.de">pressestelle@tu-berlin.de</a><br />
            Adress:<br />
            Straße des 17. Juni 135<br />
            10623 Berlin <br /></p>
       
            <h3>Content responsibility</h3>
            <p><strong>Prof. Dr.-Ing. Enrico Stoll (Head of Chair of Space Technology) </strong><br />
            <a href="mailto:e.stoll@tu-berlin.de">e.stoll@tu-berlin.de</a><br />
            Adress:<br />
            Marchstraße 12-14<br />
            10587 Berlin <br /></p>

        </div>
    );
    return (
        <>
          

            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1200,
                    maxHeight: '80vh',
                    overflow: 'auto',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2
                }}>
                    <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
                    <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                        <h1>Site Credits</h1>
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        {siteCreditText}

                    </Typography>
                    
                </Box>
            </Modal>
        </>
    );
}

export default SiteCreditsModal;
