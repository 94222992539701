const dataKeys = ["psd_00", "psd_01", "psd_02", "psd_03", "psd_04", "psd_05", "psd_06", "psd_07", "psd_08", "psd_09",
    "psd_10", "psd_11", "psd_12", "psd_13", "psd_14", "psd_15", "psd_16", "psd_17", "psd_18", "psd_19",
    "psd_20", "psd_21", "psd_22", "psd_23", "psd_24", "psd_25", "psd_26", "psd_27", "psd_28", "psd_29",
    "psd_30", "psd_31"];

const isEmailValid = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
    } else {
        return false
    }
};

const generatePSDArray = (obj) => {
    let PSDArray = [];
    dataKeys.forEach(key => {
        var psd = obj[key];
        for (var i = 0; i < psd.length; i += 2) {
            var byte = parseInt(psd.substring(i, i + 2), 16) & 0b01111111;
            let dbfs = ((byte) + (byte >> 7) * 0.5) - 128;
            PSDArray.push(dbfs);
        }
    });
    return PSDArray;
};

const download = function (data) {

    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: 'text/csv' });

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob);

    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a');

    // Passing the blob downloading url
    a.setAttribute('href', url);

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', 'download.csv');

    // Performing a download with click
    a.click();
}
const range= function(start,end,length){
    var arr = [];
    var step = (end - start) / (length - 1);
    for (var i = 0; i < length; i++) {
        arr.push(start + (step * i));
    }
  return arr;
};
const csvmaker = function (data) {

    const columns = ["id", 'Date', 'Lat', 'Lon', 'Band', 'Center Freq [MHz]', 'Bandwidth [MHz]', 'Median', 'Integration Time', 'PSD'];

    // Empty array for storing the values
    var csvRows = [];

    // Headers is basically a keys of an
    // object which is id, name, and
    // profession
    const headers = [];

    // As for making csv format, headers
    // must be separated by comma and
    // pushing it into array

    columns.forEach(element => headers.push(element));

    csvRows.push(headers.join(','));

    const values = data.forEach(element => csvRows.push((Object.values(element).join(';'))));

    // Pushing Object values into array
    // with comma separation
    // const values = Object.values(data).join(',');
    // csvRows.push(values)


    // Returning the array joining with new line
    return csvRows.join('\n');
}

// In helperFunctions.js

export const downloadFFTData = (data, filename) => {
    const csvData = csvmaker(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export {
    isEmailValid,
    generatePSDArray,
    download,
    csvmaker,
    range,
};
