// import components and modules
import React, { useEffect, useState } from 'react';
import { Slider, Typography, Button, Box, Grid, ToggleButton, ToggleButtonGroup, ButtonGroup, Checkbox, FormControlLabel, FormGroup } from '@mui/material/';

// import functions and style
import './controls.css';
import RequestSpectralDataModal from './requestSpectralDataModal.js'
import HelpModal from './HelpModal.js';



const Controls = ({ onDecimModeChange, onIntegrationTimeChange, ...props }) => {

    const dateRanges = [];
    props.data.forEach(element => dateRanges.push(element.raw));
    dateRanges.sort();
    const startRaw = dateRanges[0];
    const endRaw = dateRanges[dateRanges.length - 1];
    const epoch2000 = new Date(2000, 0, 1).getTime();
    const sd = new Date((props.timespan[0] / 1e6) + epoch2000);
    const ed = new Date((props.timespan[1] / 1e6) + epoch2000);

    const [sliderRange, setSliderRange] = useState([startRaw, endRaw]);
    const [dateRange, setDateRange] = useState([startRaw, endRaw]);
    const [radius, setRadius] = useState(1000000);
    const [band, setBand] = useState("UHF");
    const [data, setData] = useState(props.data);
    const [viewState, setView] = useState("globe");
    const [midFreq, setFreq] = useState(435);
    const [midFrequencies,setFreqs]=useState(props.midFrequencies);
    const [decimModeChecks, setDecimModeChecks] = useState({ '0': true, '1': true, '2': true });

    

    //Dateslider
    const handleDateRangeChangeUpdateSlider = (event, newRange, activeThumb) => {
        setSliderRange(newRange);
    };

    const handleDateRangeChange = (event, newRange) => {
        setDateRange(newRange);
    };
    

    //FreqBand Tabs
    function freq2band(band_freq){
        if (band_freq==435){
            return "UHF"
        }
        else if (band_freq==2080){
            return "SBAND"
        }
        else if(band_freq==150){
            return "VHF"
        }
    }

    const handleBandChange = (event) => {
        setBand(freq2band(event.target.value));
        setFreq(event.target.value);
    };


    //Radiusslider
    const handleRadiusChange = (event, newValue) => {
        setRadius(newValue * 1000);
    };

    //View Tab
    const handleViewChange = (event) => {
        setView(event.target.value);
    };

    //FreqSlider
    const handleFreqSlider = (event, newFreq) => {
        setFreq(newFreq);
        console.log(radius);
    };

   
    //Integrationtime Checkboxes

     const handleIntegrationTimeCheck = (value) => {
    setIntegrationTimeChecks(prev => {
      const newChecks = { ...prev, [value]: !prev[value] };
      onIntegrationTimeChange(newChecks);
      return newChecks;
    });
  };


  const handleDecimModeCheck = (mode) => {
    setDecimModeChecks(prev => {
      const newChecks = { ...prev, [mode]: !prev[mode] };
      onDecimModeChange(newChecks);
      return newChecks;
    });
  };


//UseEffects for Props changes
  useEffect(() => {
    onIntegrationTimeChange(integrationTimeChecks);
  }, []);

    useEffect(() => {
        setFreqs(props.midFrequencies);
      }, [props.midFrequencies]);

 
    useEffect(() => {
        // Callbacks, die auf Änderungen in den props reagieren
        props.onChange(radius);
        props.onChangeBand(band);
        props.onDateRangeChange(dateRange);
        props.onDataChange(data);
        props.onViewChange(viewState);
        props.onFreqChange(midFreq);
      }, [radius, band, dateRange, data, viewState, midFreq]);


    const dateLables = [
        {
            value: props.timespan[0],
            label: sd.toLocaleDateString('de-DE')
        },
        {
            value: props.timespan[1],
            label: ed.toLocaleDateString('de-DE')
        }
    ];


    function dateSliderText(value) {
        var curDate = new Date((value / 1e6) + epoch2000);
        return curDate.toLocaleDateString('de-DE');
    }

    const bandItems = [
        // { band: "VHF",value:150 }, 
    { band: "UHF",value:435 }, 
    // { band: "SBAND",value:2080 },
];

    
    const IntegrationTimeMarks = [
         {
             value: 5,
             label: "2 ms",
        },
        {
            value: 2440,
            label: "2.000 ms",
        },
        {
            value: 36640,
            label: "20.000 ms"
        }
    ];


    const [integrationTimeChecks, setIntegrationTimeChecks] = useState(
        IntegrationTimeMarks.reduce((acc, mark) => {
          acc[mark.value] = true; 
          return acc;
        }, {})
      );


    const radiusMarks=[
        
        {
            value:200,
            label:"200"
        },
        {
            value:400,
            label:""
        },
        {
            value:500,
            label:""
        },
        
        {
            value:1000,
            label:""
        },

        {
            value:2000,
            label:"2000"
        },
    ];
    
   
    const decimModeOptions = [
        { label: 'Max Hold', value: '0' },
        { label: 'Min Hold', value: '1' },
        { label: 'Average Hold', value: '2' }
      ];

    function freqValueText(value) {
        return `${value}`;
    }

    const generateIntegrationTimeCheckboxes = () => {
        return (
            <Box display="flex" justifyContent="space-between" width="100%" mb={2}>
                {IntegrationTimeMarks.map(mark => (
                    <Box key={mark.value} width="30%">
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={integrationTimeChecks[mark.value]} 
                                    onChange={() => handleIntegrationTimeCheck(mark.value)} 
                                />
                            }
                            label={<span>{mark.label}</span>}
                            style={{ flexDirection: 'column', alignItems: 'center' }} 
                        />
                    </Box>
                ))}
            </Box>
        );
    };
    
    const generateDecimModeCheckboxes = () => {
        return (
            <Box display="flex" justifyContent="space-between" width="100%" mt={1}>
                {decimModeOptions.map(option => (
                    <Box key={option.value} width="30%">
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={decimModeChecks[option.value]} 
                                    onChange={() => handleDecimModeCheck(option.value)} 
                                />
                            }
                            label={<span>{option.label}</span>}
                            style={{ flexDirection: 'column', alignItems: 'center' }} 
                        />
                    </Box>
                ))}
            </Box>
        );
    };
    
    // Modal
    const [ModalOpen, setModalOpen] = React.useState(false);
    const [HelpModalOpen, setHelpModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    const handleHelpModalOpen = () => setHelpModalOpen(true);
    const handleHelpModalClose = () => setHelpModalOpen(false);

    const generateMenuItems = () => {
        return (
            bandItems.map((menu, index) => {
                return (
                    <Button
                        id="basic-button"
                        variant={band === menu.band ? "contained" : "text"}
                        aria-haspopup="true"
                        onClick={handleBandChange}
                        value={menu.value}
                    >
                        {menu.band}
                    </Button>
                );
            })
        );
    };
   
    const generateMiddleFreqSlider = () => {
        let marks = [];
    
        if (!midFrequencies || !Array.isArray(midFrequencies)) {
            return null;
        }
    
        let frequencies = [];
        if (band === "SBAND") {
            frequencies = midFrequencies[1];
        } else if (band === "UHF") {
            frequencies = midFrequencies[0];
        } else if (band === "VHF") {
            frequencies = midFrequencies[2];
        }
    
       
        let minFreq = frequencies.length > 0 ? Math.min(...frequencies) : 0;
        let maxFreq = frequencies.length > 0 ? Math.max(...frequencies) : 0;
    
        if (frequencies.length > 0) {
          
            if (frequencies.length === 1) {
                marks.push({ value: minFreq, label: freqValueText(minFreq) });
            } else {
                frequencies.forEach(element => {
                    let label = null;
                    if (element === minFreq || element === maxFreq ||element===435) {
                        label = freqValueText(element);
                    }
                    marks.push({ value: element, label: label });
                });
            }
        } else {
            return null;
        }
    
        return (
            <Slider
                aria-label="middle-Freq"
                defaultValue={band=="UHF"?(435):(minFreq)}
                getAriaValueText={freqValueText}
                valueLabelDisplay="auto"
                step={null}
                min={minFreq}
                max={maxFreq}
                marks={marks}
                track={false}
                sx={{
                    '& .MuiSlider-markLabel': {
                        color: "#ffffff"
                    }
                }}
                onChange={handleFreqSlider}
            />
        );
    };

    return (
        <div class="control-panel" >
         {/* style={{ width: '350px' }}> */}
         <div className='flexbox'>
            <center>
                <div class="float-container">
                    <div class="float-child"><a href="https://www.tu.berlin/raumfahrttechnik/forschung/aktuelle-projekte/salsat" target="_blank"><img src="img/salsat_logo.png"  alt="" width="80%" height="80%"></img></a></div>
                    <div><p>&nbsp;&nbsp;&nbsp;</p></div>
                    <div class="float-child" ><h3 class="title">SALSAT<br /> Mission<br />Data</h3></div>
                    <div class="float-child"><a href="https://www.tu.berlin/" target="_blank"><img src="img/tu_berlin_logo.png" alt=""  width="80%" height="60%"></img></a></div>
                </div>

                <hr />
                {generateMenuItems()}
                <hr />
                


<Typography id="input-slider" style={{ marginTop: '20px' }}>
    Middle Frequency [MHz]
</Typography>

                {generateMiddleFreqSlider()}
                
                <Typography id="input-slider">
                    {viewState == "map" ? ("Radius at equator [KM]"):("Radius at Equator [KM]")}
                </Typography>
                <Slider
                    aria-label="Radius"
                    defaultValue={1000}
                    // getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    step={viewState == "map" ? (100) : (null)}
                    min={viewState == "map" ? (100) : (200)}
                    max={2000}
                    marks={viewState == "map" ? ([{ value: 100, label: "100" }, { value: 2000, label: "2000" }]):(radiusMarks)}
                    onChange={handleRadiusChange}
                    sx={{
                        '& .MuiSlider-markLabel': {
                            color: "#ffffff"
                        }
                    }}
                />
                <Typography id="input-slider">
                    Date Range
                </Typography>
                <Slider
                    value={sliderRange}
                    onChangeCommitted={handleDateRangeChange}
                    onChange={handleDateRangeChangeUpdateSlider}
                    valueLabelFormat={dateSliderText}
                    marks={dateLables}
                    valueLabelDisplay="auto"
                    min={props.timespan[0]}
                    max={props.timespan[1]}
                    disableSwap
                    sx={{
                        '& .MuiSlider-markLabel': {
                            color: "#ffffff"
                        }
                    }}
                />
                <Typography id="input-slider">
                    Integration Time
                </Typography>

                {generateIntegrationTimeCheckboxes()}
                <Typography id="recording-mode-title" >
                    RECORDING MODE
                </Typography>
                {generateDecimModeCheckboxes()}
                <br />
                <Button value="" onClick={handleModalOpen}>Request Future Recording</Button>
                <br />
                
                <RequestSpectralDataModal
                    ModalOpen={ModalOpen}
                    onClose={handleModalClose}
                />
                <br />
                {/* <ToggleButtonGroup color='primary' onChange={handleViewChange} exclusive value={viewState} 
                >
                    <ToggleButton value="map"> 
                        Map View
                    </ToggleButton>
                    <ToggleButton value="globe"> 
                        Globe View
                    </ToggleButton>
                </ToggleButtonGroup> */}
                <div style={{ marginTop: 10 }}> 
    <ButtonGroup>
        <Button
            id="map-view-button"
            variant={viewState === "map" ? "contained" : "text"}
            onClick={handleViewChange}
            value="map"
        >
                        MAP VIEW
                    </Button>
                    <Button
            id="globe-view-button"
            variant={viewState === "globe" ? "contained" : "text"}
            onClick={handleViewChange}
            value="globe"
        >
            GLOBE VIEW
        </Button>
                </ButtonGroup>
                
                <Button
                id="help-button"
                variant="text"
                onClick={handleHelpModalOpen}
                style={{ marginTop: 8, display: 'block', width: '100%' }} 
            >
                Help
            </Button>   
            
            <HelpModal HelpModalOpen={HelpModalOpen} onClose={handleHelpModalClose} />
            
        </div>
        </center>
        </div>
    </div>
);
};

export default React.memo(Controls);

