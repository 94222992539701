import React from 'react';
import { Modal, Box, Typography, Accordion, AccordionSummary, AccordionDetails, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '1000px',
  maxHeight: '80vh', 
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const HelpModal = ({ HelpModalOpen, onClose }) => {
  return (
    <Modal
      open={HelpModalOpen}
      onClose={onClose}
      aria-labelledby="help-modal-title"
      aria-describedby="help-modal-description"
    >
      <Box sx={style}>
      <IconButton
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="help-modal-title" variant="h6" component="h2">
          Help & FAQ
        </Typography>
         
        <Typography sx={{ mt: 2 }}>
        <div>
            <p>Welcome to the SALSAT Satellite Mission Website of the TU Berlin! Our mission, started in September 2020, revolves around the recording of spectral data from space. 
              Our payload SALSA operates in <strong>VHF, UHF and S-Band</strong> but our recordings are mainly in the UHF Band between <strong>430-440 MHz</strong>. The essence of this mission is to meticulously monitor and quantify the occupancy levels of frequency bands globally, providing invaluable insights into their usage patterns.
               </p>
               <p>This website is an extension of the SALSAT mission, serving as an interactive platform for the public and researchers alike to access and engage with the data collected by the SALSAT satellite. It is designed to offer:
<ul>
<li>A comprehensive visualization of the frequency band usage through a heatmap overlay on a world map.</li>
<li>Detailed data representation in the form of hexagons on the map, offering a clear and precise depiction of the collected data.</li>
<li>Accessibility to download the data in CSV format, allowing for further analysis and research.</li>
<li>Waterfall diagrams for each data capture session, available for download, which provide a visual representation of the spectral data over time.</li>
</ul>

By visiting our website, you gain direct access to the results of the SALSAT mission, enabling a deeper understanding of global frequency band utilization.</p>

<h3>FAQ</h3>
          </div>
        </Typography>

        {/* Beispiel für ein FAQ-Akkordeon */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight="bold">How often is the data updated?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            The data on our website is updated regularly, following each data transmission from SALSAT to our ground station.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography fontWeight="bold">Can I request data for a specific frequency range or time?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Yes, we are happy to accept requests for any data recordings, Please use the "Request future Recording" formular to get in contact with us and tell us about the desired frequency, coordinates and time.
            </Typography>
          </AccordionDetails>
        </Accordion>

      

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography fontWeight="bold">Are there any restrictions on downloading the data?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            All data available on the website can be freely downloaded in CSV format. The waterfall diagrams of the recordings can be downloaded as PNG.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography fontWeight="bold">How can I filter the heatmap data?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            To filter the data, there are several option which can be combined. First, select the frequency band in which you are interested in. At the moment, most of our data is in the UHF band. To select the frequency, use the "Middle Frequency"- Slider. The bandwith of the recordings is 10 MHz.
            You can filter the recording dates with the recording "Date Range"-Slider. The integration time is selectable via the checkboxes.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography fontWeight="bold">What is the radius slider used for?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            You can you use radius slider to choose the size of the data hexagons. If the radius is small, you can select single recordings, if you choose a bigger size, you can plot and download a whole area of spectral data.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            <Typography fontWeight="bold">What is the integration time?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Integration Time in the context of a Fast Fourier Transform (FFT) recording, particularly when using the Max Hold setting, refers to the duration over which the signal is observed or sampled to determine its maximum value at each frequency point.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            <Typography fontWeight="bold">How can I download the data and why do you need my e-mail adress?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            You can download the csv data using the "Download Data"-Button below the data table. We are only using your e-mail adress to get in touch with you if we have any questions. We do not save the adress or forward them to third parties. (see also Data Protection)
            </Typography>
          </AccordionDetails>
        </Accordion>

       
      </Box>
    </Modal>
  );
};

export default HelpModal;
