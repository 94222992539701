import React, { useEffect, useState ,useMemo} from "react";
import { StaticMap } from 'react-map-gl';
import DeckGL from "@deck.gl/react";
import {MapView,_GlobeView as GlobeView,COORDINATE_SYSTEM,LightingEffect,  AmbientLight,  _SunLight as SunLight} from '@deck.gl/core';
import { HexagonLayer } from '@deck.gl/aggregation-layers';
import Controls from "./controls";
import DataPanel from "./dataPanel";
import './App.css';
import {GeoJsonLayer,SolidPolygonLayer,BitmapLayer,PolygonLayer} from '@deck.gl/layers';
import {SimpleMeshLayer} from '@deck.gl/mesh-layers';
import {TerrainLayer,H3HexagonLayer} from '@deck.gl/geo-layers';
import DataProtectionModal from "./DataProtection.js";
import {SphereGeometry} from '@luma.gl/core';
import { AttributionControl } from 'react-map-gl';
import {
  isEmailValid,
  generatePSDArray,
  download
} from './helperFunctions.js';
import nightsky from './nightsky_background.jpg';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import SiteCreditsModal from "./SiteCredits.js";
import Counter from "./counter.js"



const EARTH_RADIUS_METERS = 6.3e6;
const EARTH_CIRCUMFERENCE=40000;//km

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 2
});
const sunLight = new SunLight({
  color: [255, 255, 255],
  intensity: 3.0,
  timestamp: Date.now()
});
// create lighting effect with light sources
const lightingEffect = new LightingEffect({ambientLight, sunLight});


const material = {
  ambient: 0.64,
  diffuse: 0.6,
  shininess: 32,
  specularColor: [51, 51, 51]
};

const INITIAL_VIEW_STATE = {
  longitude: -1.415727,
  latitude: 52.232395,
  zoom: 0,
  minZoom: 0,
  maxZoom: 10,
  pitch: 0,
  bearing: 0
};

const INITIAL_VIEW_STATE_GLOBE = {
  longitude: -1.415727,
  latitude: 52.232395,
  zoom: 0.75,
  minZoom: 0,
  maxZoom: 10,
  pitch: 0,
  bearing: 0
};

const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json';


let loadingVideo = require("./SALSAT_INTRO.mp4")

const colorRange = [
  [8, 29, 88],
  [37, 52, 148],
  [34, 94, 168],
  [29, 145, 192],
  [65, 182, 196],
  [127, 205, 187],
  [199, 233, 180],
  [237, 248, 177],
  [255, 255, 217],
  [255, 255, 204],
  [255, 237, 160],
  [254, 217, 118],
  [254, 178, 76],
  [253, 141, 60],
  [252, 78, 42],
  [227, 26, 28],
  [189, 0, 38],
  [128, 0, 38]
];
let amplitude_range=[];
for (let i=0;i<colorRange.length;i++){
  amplitude_range.push(-i*(127/colorRange.length));
};
amplitude_range=amplitude_range.reverse();


function findClosestValue(array, target) {
  let closestValue = array[0];
  let minDifference = Math.abs(target - closestValue);

  for (let i = 1; i < array.length; i++) {
      const difference = Math.abs(target - array[i]);
      if (difference < minDifference) {
          minDifference = difference;
          closestValue = array[i];
      }
  }
 
  return closestValue;
};

function getSquareColour(val){
  let closestValue=findClosestValue(amplitude_range,val);
  let color=colorRange[amplitude_range.indexOf(closestValue)];
  
  return color;
};
function getRandomColor() {
  
  // Generate a random index to pick a color from the array
  const randomIndex = Math.floor(Math.random() * colorRange.length);

  // Retrieve the color from the array using the random index
  const randomColor = colorRange[randomIndex];

  // Return the randomly selected color
  return randomColor;
};
const getTooltip = ({ object }) => {
  if (!object) {
    return null;
  }
  
  const lat = object.position[1];
  const lon = object.position[0];
  const count = object.points.length;
  const colorVal = object.colorValue;
  // const elevationVal = object.elevationValue;
  // const val = object.

  return `\
    Latitude: ${Number.isFinite(lat) ? lat.toFixed(3) : ''}
    Longitude: ${Number.isFinite(lon) ? lon.toFixed(3) : ''}
    ${count} FFTs
    Median dBFS: ${colorVal.toFixed(2)}
    `;
};

const COLOR_DOMAIN = [
  -128, -10
];

const getSalsa = (points, g) => {
  // console.log(points);
  // console.log(a);
  // return points.reduce((sum, p) => sum += p.SPACES, 0) / points.length;
  // console.log(points.length*40);
  // A = 0
  // for i in range(len(points)):
  //   A += points[i][2]
  // return A/len(points)

  // var mean_lon = 0
  // points.forEach(element => a = a + element[2]);
  // const lat = object.position[1];
  // const lon = object.position[0];
  // console.log(lat)
  // console.log(g);
  // console.log(points);
  var a = 0;
  points.forEach(element => a = a + element["val"]);
  // console.log(a / points.length);
  
  return a / points.length;
  // return -100;
  // return points.length*40;
};
const calculateAverage=(vals)=>{
  var a=0;

  vals.forEach(element=>a=a+element);
 
  return a/vals.length;
};
var idCounter = 0;

const createRow = (data) => {
  idCounter += 1;
  // console.log(data);
  return { id: idCounter, Date: data.tt, Lon: data.coordinates[0], Lat: data.coordinates[1], MHz: data.val, Band: data.band, MidFreq: data.freq, Width: data.bw, Median: data.val };
};
let freq_list=[];

const extractBandFromAntenna = (antenna) => {
  if (antenna.includes('UHF')) {
    return 'UHF';
  } else if (antenna.includes('SBAND')) {
    return 'SBAND';
  } else if (antenna.includes('VHF')) {
    return 'VHF';
  }
  return 'UHF'; 
};

// load from DB
const loadData = async () => {
  var heatmap_data = [];
  const metadata = (await (await fetch("/api/metadata")).json())["result"];
  const spectrum = (await (await fetch("/api/spectrumMedian")).json())["result"];
  spectrum.forEach(element => {
    // console.log(element);
    let m = metadata.filter(obj => { return obj["fft_time_raw"] === element["fft_time_raw"] });
    let band = extractBandFromAntenna(element["antenna"]);
    heatmap_data.push({ coordinates: [parseFloat(m[0]["lon"]), parseFloat(m[0]["lat"])], val: element["median"], band: band, freq: element["frequency"], bw: element["bandwidth"], tt: element["fft_time_utc"], raw: element["fft_time_raw"], decim_time: element["FFT_MAX_DECIM_TIME"],  decim_mode: element["FFT_DECIM"]});//element["antenna"].slice(5)
    freq_list.push(element["frequency"]);
  });
  // console.log("Heatmap Data:", heatmap_data);
  // console.log("MetaDaten:", metadata);
  // console.log("Spektrum:", spectrum);
  return heatmap_data;
};

const loadTimespan = async () => {
  const firstDate = (await (await fetch("/api/firstdate")).json())["result"];
  const lastDate = (await (await fetch("/api/lastdate")).json())["result"];
  const f = firstDate[0]["fft_time_raw"];
  const l = lastDate[0]["fft_time_raw"];
  return [f, l];
};



/* eslint-disable react/no-deprecated */
const App = ({
  mapStyle = MAP_STYLE,
  upperPercentile = 100,
  coverage = 1,
  opacity = 0.1
}) => {

  // Loading state 
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [timespan, setTimespan] = useState([]);
  const [midFrequencies,setMidFrequencies]=useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openSiteCredits, setOpenSiteCredits] = useState(false);
  const [decimModeChecks, setDecimModeChecks] = useState({ '0': true, '1': true, '2': true });

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenSiteCredits = () => setOpenSiteCredits(true);
  const handleCloseSiteCredits = () => setOpenSiteCredits(false);


  const getFrequencies = async () => {
    await loadData();
    let freq_uniq = freq_list.filter((item, pos ,self) => self.indexOf(item) === pos);
    
    freq_uniq.sort(function(a,b){return a-b});
   
  
    if (freq_list.length > 0) {
      let intervals = [[400,500],[2000,2200],[100,200]];
      let freqsByBand = [[],[],[]]; 
      for (const freq of freq_uniq) {
        if (freq >= intervals[0][0] && freq <= intervals[0][1]) {
          freqsByBand[0].push(freq);
        } else if (freq >= intervals[1][0] && freq <= intervals[1][1]) {
          freqsByBand[1].push(freq);
        } else if (freq >= intervals[2][0] && freq <= intervals[2][1]) {
          freqsByBand[2].push(freq);
        }
      }
      
      
      setMidFrequencies(freqsByBand); 
    } else {
     
    }
    setIsLoading(false);
    console.log("done loading!");
  };
  


  // useEffect(() => {
  //   console.log("Data", data);
  // }, [data]);
  

  useEffect(() => {
    const fetchData = async () => {
     
      const loadedData = await loadData();
      setData(loadedData);

      
      const timespanData = await loadTimespan();
      setTimespan(timespanData);

     
      const frequencies = await getFrequencies();
      
      
      if (frequencies && frequencies.length > 0) {
        setMidFrequencies(frequencies);
      }
    };

    fetchData();
  }, []);
  
  
  const [radius, setRadius] = useState(1000000);
  const [band, setBand] = useState("UHF");
  const [dateRange, setDateRange] = useState([0, 0]);
  const [selected, setSelected] = useState(null);
  const [dispData, setDispData] = useState([]);
  const [CSVData, setCSVData] = useState(data);
  const [isDataPanelOpen, setIsDataPanelOpen] = useState(false);
  const [viewState, setView] = useState("map");
  const [midFreq,setFreq]=useState(435);
  const [integrationTime, setIntegrationTime] = useState(5);
  const [integrationTimeChecks, setIntegrationTimeChecks] = useState({});

  const radiusInKm=radius/1000;

  function getGlobeData(object){
    let square=object;
    let globe_data=data.filter(d=>(d.coordinates[0]>=square.bounds[3]&&d.coordinates[0]<=square.bounds[0]&&d.coordinates[1]>=square.bounds[2]&&d.coordinates[1]<=square.bounds[1]))
    
    return globe_data
  };
  const getGlobeTooltip=({ object }) => {
    if (!object) {
      return null;
    }
    // console.log(object);
    const lat = object.coordinates[1];
    const lon = object.coordinates[0];
    
   
    // const elevationVal = object.elevationValue;
    // const val = object.
  
    return `\
      Latitude: ${Number.isFinite(lat) ? lat.toFixed(3) : ''}
      Longitude: ${Number.isFinite(lon) ? lon.toFixed(3) : ''}
      ${object.length} FFTs
      Median dBFS: ${object.value.toFixed(2)}
      `;
  };
  const handleHexOnClick = ({ x, y, coordinate, lngLat, layer, color, object, index }) => {
    if (object) {
      // console.log(object);
      var locDispData = []
      object.points.forEach(element => locDispData.push(element.source))
      // console.log(object.points);
      var locDispData2 = []
      locDispData.forEach(element => locDispData2.push(createRow(element)))
      idCounter = 0;
      setDispData(locDispData2)
      setSelected({ x, y, coordinate, object });
      setIsDataPanelOpen(true);
    } else {
      // clicked off an object
      setSelected(null);
      setIsDataPanelOpen(false);
    }
  };

  // check if data in range, else load data

  const handlePolyOnClick=({x, y, coordinate,object})=> {
    if (object&&object.value) {
      // console.log(object);
      var locDispData = []
      object["data"].forEach(element => locDispData.push(element))
      
      var locDispData2 = []
      locDispData.forEach(element => locDispData2.push(createRow(element)))
      idCounter = 0;
      setDispData(locDispData2);
      setSelected({ x, y, coordinate, object });
      setIsDataPanelOpen(true);
    } else {
      // clicked off an object
      setSelected(null);
      setIsDataPanelOpen(false);
    }
  };

  // function generateHexagonContour(radiusInKm,centers) {
  //   var hexagons=[];
  //   for(let i = 0; i < centers.length; i++){
  //     var center={latitude:centers[i][0],longitude:centers[i][1]}
  //     var numberOfVertices=6;
      

  //     var hexagonContour = Array.from({ length: numberOfVertices }, (_, index) => {
  //       var angle =(index / numberOfVertices) * (2 * Math.PI);
  //       var latitude = center.latitude + (radiusInKm  * Math.cos(angle))*360/EARTH_CIRCUMFERENCE;
  //       var longitude = center.longitude + (radiusInKm * Math.sin(angle))*360/(EARTH_CIRCUMFERENCE*Math.cos(latitude*Math.PI/180));
        
  //       return [longitude, latitude];
  //     });
      
  //     hexagons.push({"center":centers[i],"value":0,"contour":hexagonContour});
  //   }
  //   return hexagons;
  // }
//   function randomIntFromInterval(min, max) { // min and max included 
//     return Math.floor(Math.random() * (max - min + 1) + min)
//   };
//   function calculateMedian(array) {
//     // First, sort the array
//     array.sort((a, b) => a - b);

//     const length = array.length;
//     // Check if the array is empty
//     if (length === 0) {
//         return 0;
//     }

//     // Check if the length of the array is odd
//     if (length % 2 !== 0) {
//         return array[Math.floor(length / 2)];
//     } else {
//         // If the length is even, return the average of the two middle elements
//         const midIndex = length / 2;
//         return (array[midIndex - 1] + array[midIndex]) / 2;
//     }
// }

  function generateSquareContour(radiusInKm,centers) {
    var squares=[];
    for(let i = 0; i < centers.length; i++){
      var center={longitude:centers[i][0],latitude:centers[i][1]}
      var numberOfVertices=4;
      
      var sqaureContour = Array.from({ length: numberOfVertices }, (_, index) => {
        var angle =((index / numberOfVertices) * (2 * Math.PI))+Math.PI/4;
        var latitude = center.latitude + (radiusInKm  * Math.cos(angle)/(Math.abs(Math.cos(angle))))*360/EARTH_CIRCUMFERENCE;
        var longitude = center.longitude + (radiusInKm * Math.sin(angle)/(Math.abs(Math.cos(angle))))*360/(EARTH_CIRCUMFERENCE);
        
        return [longitude, latitude];
      });
                     //east                      north           south             west
      let bounds=[sqaureContour[1][0],sqaureContour[0][1],sqaureContour[1][1],sqaureContour[3][0]]

      let globe_data=data.filter(d=>(d.coordinates[0]>=bounds[3]&&d.coordinates[0]<=bounds[0]&&d.coordinates[1]>=bounds[2]&&d.coordinates[1]<=bounds[1]&&d.band === band && d.raw >= dateRange[0] && d.raw <= dateRange[1] &&d.freq==midFreq && integrationTimeChecks[d.decim_time]&&(d.decim_mode != null ? decimModeChecks[d.decim_mode.toString()] : true)));
      let vals=[];
      globe_data.forEach(element=>vals.push(element["val"]));
      let average_val=calculateAverage(vals);
      
      let color=getSquareColour(average_val);
      squares.push({"coordinates":centers[i],"length":globe_data.length,"value":average_val,"color":color,"contour":sqaureContour,"bounds":bounds,"data":globe_data});
    }
    return squares;
  }
  document.body.style.background = 'black'

  
  
  //generate Center Coordinates for Globe Sqaures
  function generateCenterCoordsSquare(){
    const coords=[];
    
    const lon_angle=2*(radiusInKm )*360/EARTH_CIRCUMFERENCE;
    const lat_angle=lon_angle;
    const n_lat=Math.floor(360/lat_angle);
    const n_lon=Math.floor(360/lon_angle);

    for(let j=-(Math.floor(n_lat/4));j<=Math.floor(n_lat/4);j++){
      for(let i=-(n_lon/2);i<n_lon/2;i++){
        coords.push([lon_angle*i,j*lat_angle]);
        
      };
      
    }
    return coords;
  };
  // function generateCenterCoords(){
  //   const coords=[];
  //   coords.push([0,0]);
    
  //   const lon_angle=2*(radiusInKm  *( Math.sqrt(3)/2))*360/EARTH_CIRCUMFERENCE;
  //   const lat_angle=lon_angle;
  //   const n_lat=Math.floor(360/lat_angle);
  //   const n_lon=Math.floor(360/lon_angle);

  //   for(let j=0;j<n_lat/2;j++){
  //     for(let i=0;i<n_lon/2;i++){
  //       coords.push([coords[0][0]+j*lat_angle,coords[0][1]+lon_angle*i+(lon_angle/2)*j])
        
  //     };
      
  //   }
  //   return coords;
  // };
  const centerCoords=generateCenterCoordsSquare();

   // Update heatmap data based on decim mode checks
   
  //console.log(radius);
  const layers = [
    
    new HexagonLayer({
      id: 'heatmap',
      colorRange,
      colorDomain: COLOR_DOMAIN,
      coverage,
      data: data.filter(d => (d.band === band && d.raw >= dateRange[0] && d.raw <= dateRange[1] &&d.freq==midFreq && integrationTimeChecks[d.decim_time] &&
        (d.decim_mode != null ? decimModeChecks[d.decim_mode.toString()] : true))),
      opacity,
      elevationRange: [0,0],//[0, radius/1000],
      extruded: true,
      elevationScale: 1000,
      getPosition: d => d.coordinates,
      pickable: true,
      radius,
      upperPercentile,
      material,
      getColorValue: (data, info) => getSalsa(data, info),

    })
  ];
  const backgroundLayers = 
  
    [
      
      new BitmapLayer({
        id: 'WORLD_MAP',
        image: '//unpkg.com/three-globe/example/img/earth-blue-marble.jpg',
        //image:'https://smd-cms.nasa.gov/wp-content/uploads/2023/09/324350main_11_full.jpg',
        bounds: [-180, -90, 180, 90]
      }),
      new PolygonLayer({
        id: 'PolygonLayer',
        data:generateSquareContour(radiusInKm,centerCoords),
                
        // elevationScale: 1,
        // extruded: false,
        filled: true,
        
        getFillColor: d => d.color,
        getLineColor: [255, 255, 255],
        getLineWidth: d => 100,
        getPolygon: d => d.contour,
      
        // lineJointRounded: false,
        // lineMiterLimit: 4,
        // lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
        lineWidthMinPixels: 10,
        // lineWidthScale: 1,
        // lineWidthUnits: 'meters',
        // material: true,
        stroked: true,
        wireframe: true,
        opacity:0.2,
        /* props inherited from Layer class */
        
        // autoHighlight: false,
        // coordinateOrigin: [0, 0, 0],
        // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
        // highlightColor: [0, 0, 128, 128],
        // modelMatrix: null,
        // opacity: 1,
        pickable: true,
        // visible: true,
        // wrapLongitude: false,
        elevationRange: [0, 10],
        extruded: true,
        elevationScale: 10,
      }),
      // new H3HexagonLayer({
      //   id: 'H3HexagonLayer',
      //   data: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/sf.h3cells.json',
        
      //   /* props from H3HexagonLayer class */
        
      //   // centerHexagon: null,
      //   // coverage: 1,
      //   elevationScale: 20,
      //   extruded: true,
      //   filled: true,
      //   getElevation: d => d.count,
      //   getFillColor: d => [255, (1 - d.count / 500) * 255, 0],
      //   getHexagon: d => d.hex,
      //   // getLineColor: [0, 0, 0, 255],
      //   // getLineWidth: 1,
      //   // highPrecision: 'auto',
      //   // lineJointRounded: false,
      //   // lineMiterLimit: 4,
      //   // lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
      //   // lineWidthMinPixels: 0,
      //   // lineWidthScale: 1,
      //   // lineWidthUnits: 'meters',
      //   // material: true,
      //   // stroked: true,
      //   wireframe: false,
        
      //   /* props inherited from Layer class */
        
      //   // autoHighlight: false,
      //   // coordinateOrigin: [0, 0, 0],
      //   // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
      //   // highlightColor: [0, 0, 128, 128],
      //   // modelMatrix: null,
      //   // opacity: 1,
      //   pickable: true,
      //   // visible: true,
      //   // wrapLongitude: false,
      // }),
      
      // new HexagonLayer({
      //   id: 'heatmap_globe',
      //   colorRange,
      //   colorDomain: COLOR_DOMAIN,
        
      //   data: data.filter(d => (d.band === band && d.raw >= dateRange[0] && d.raw <= dateRange[1]&&d.freq==midFreq)),
      //   opacity:0.4,
      //   elevationRange: [0, 0],
      //   extruded: true,
      //   elevationScale: 1000,
      //   getPosition: d => d.coordinates,
      //   pickable: true,
      //   radius,
      //   upperPercentile:100,
      //   material,
      //   getColorValue: (data, info) => getSalsa(data, info),
      //   coverage: 1,
      
      // }),
     
    
    ]
  
  ;
  // if(isLoading==false){
  //   console.log(data);
  // };
  
  if (data[0]){
    const data1=data.filter(d=>(d.freq==midFreq));

  };
  // show loading screen or heatmap

  
  return isLoading ?
    <video class="video-container" playsInline autoPlay muted>
      <source src={loadingVideo} type="video/mp4" />
    </video>
    :
    (
      <>
      {/* <div style={{ position: 'relative' }}> */}
        <DeckGL
          views={viewState=="globe"?([new GlobeView({resolution:5})]):([new MapView()])}
          layers={viewState=="map"?(layers):(backgroundLayers)}
          // effects={[lightingEffect]}
          initialViewState={viewState=="globe"?(INITIAL_VIEW_STATE_GLOBE):(INITIAL_VIEW_STATE)}
          controller={true}
          getTooltip={viewState=="map"?(getTooltip):(getGlobeTooltip)}
          onClick={viewState=="map"?(handleHexOnClick):(handlePolyOnClick)}
          effects={viewState=="globe"?([lightingEffect]):([])}
        >
        {viewState=="map"?(
       //TODO: fix/add copyright, privacy policy, impressum
          <StaticMap reuseMaps mapStyle={mapStyle} preventStyleDiffing={true} attributionControl={false} >
            
          
          </StaticMap>
        ) : (
        
        <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `url(${nightsky})`,
          backgroundSize: 'cover',
          zIndex: -1, // Set a lower zIndex to ensure it's behind DeckGL content
        }}
        ></div>
        
        )
        }  
          
          
        </DeckGL>
        
    

    {/* </div> */}
        <div className={`controls-panel ${isDataPanelOpen ? 'closed' : ''}`}>
          <Controls
          
          key={midFrequencies ? 'loaded' : 'loading'}
          
          onChangeBand={setBand}
            onChange={setRadius}
            data={data}
            onDateRangeChange={setDateRange}
            onDataChange={setCSVData}
            timespan={timespan}
            onViewChange={setView}
            onFreqChange={setFreq}
            midFrequencies={midFrequencies || []}
            integrationTime={integrationTime}
            onIntegrationTimeChange={setIntegrationTimeChecks}
            onDecimModeChange={setDecimModeChecks}
          />
        </div>
        {isDataPanelOpen && selected && dispData && <DataPanel dispData={dispData} selected={setSelected} dataPanelOpened={setIsDataPanelOpen}/>}
        <div class="layout">
          <div style={{ color:"#ffffff", marginLeft:"-25px"}}>&#91;dBFS&#93;</div>
          <div class="legend" style={{ background: '#800026' }}><span class="units" style={{marginLeft:"-15px"}}>0</span></div>
          <div class="legend" style={{ background: '#fd8d3c' }}></div>
          <div class="legend" style={{ background: '#ffffcc' }}></div>
          <div class="legend" style={{ background: '#7fcdbb' }}></div>
          <div class="legend" style={{ background: '#225ea8' }}></div>
          <div class="legend" style={{ background: '#081d58' }}><span class="units" style={{marginLeft:"-40px"}}>-127</span></div>
        </div>

        <DataProtectionModal open={openModal} onClose={handleCloseModal} />
        <SiteCreditsModal open={openSiteCredits} onClose={handleCloseSiteCredits} />

        <div class="counter">
  <Counter />
</div>
<div class="white-box">
          <a href="https://www.tu.berlin/raumfahrttechnik/forschung/aktuelle-projekte/salsat" class="hyperlink" target="_blank" >Contact | </a>
          <a href="#" onClick={(e) => { e.preventDefault(); handleOpenSiteCredits(); }} class="hyperlink">Site Credits | </a>
          <a href="#" onClick={(e) => { e.preventDefault(); handleOpenModal(); }} class="hyperlink">Data Protection | </a>
          <a href="https://carto.com/" class="hyperlink" target="_blank" >©CARTO | </a>
          <a href="https://www.openstreetmap.org/about" class="hyperlink" target="_blank">©OpenStreetMap</a>
        </div>

        
      </>
    );
}

export default App;
