import React, {useState, useEffect} from "react";

function Counter() {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const updateVisitorCount = async () => {
            await fetch('/api/increment-visitor-count');
            const response = await fetch('/api/get-visitor-count');
            const data = await response.json();
            setCount(data.visitorCount);
        };
        updateVisitorCount();
    }, []);

    return <div className="hyperlink">Visitor counter: {count}</div>;
}

export default Counter;
