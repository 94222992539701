import React, { useEffect, useState,useLayoutEffect,useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Modal, Typography, Button, Box, TextField, IconButton,RadioGroup,Grid,FormControlLabel, Radio} from '@mui/material/';
import CloseIcon from '@mui/icons-material/Close';
import { isEmailValid, download, csvmaker , generatePSDArray,range, downloadFFTData} from './helperFunctions.js';
import Plot from 'react-plotly.js';
import { useTheme } from '@mui/material/styles';
import spinner from './loading_salsat.gif';
import './dataPanel.css'
/////Define DataPanel/////

const DataPanel = (props) => {
    //Using useTheme for constant styling
    const theme=useTheme();

    //////Using props for data in panel//////
    const dispData = props.dispData;
    const setSelected=props.selected;
    const setIsDataPanelOpen=props.dataPanelOpened;

    /////////Define usestates for the datapanel///////

    const [heatmapData, setHeatmapData] = useState(null); // Initialize as null
    
    const [heatmapDataFiltered, setHeatmapDataFiltered] = useState(null); // Initialize as null
    const [date_array, setHeatmapDates] = useState(null); // Initialize as null
    const [dateArrayFiltered, setDateArrayFiltered] = useState(); 
    const [loading, setLoading] = useState(true); // Add loading state
    const [loading2, setLoading2] = useState(true); // Add loading state
    const [midFrequency, setMidFrequency] = useState();
    
    
    //////Define function for closing the modal via button///////

    const handleCloseButtonClick=()=>{setSelected(null);setIsDataPanelOpen(false)}
    
    ////// Setting States for opening and closing the Modal//////
    const [ModalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    function handleDeselect() {
        setSelectionModel([]);
        radioChecked = [null];
    }
    //////Styling for Modal///////////

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    /////////Define the columns for the data panel//////

    const columns = [
        { field: "id", width: 28 },
        { field: 'Date', width: 160 },
        { field: 'Lon', width: 80 },
        { field: 'Lat', width: 80 },
        { field: 'Band', width: 70 },
        { field: 'MidFreq', width: 100 },
        { field: 'Width', width: 80 },
        { field: 'Median', width: 80 },
    ];
    
    /////////Define Columns for selecting recordings via radiobuttons//////////

    const recordingColumns=[
        {
            field: "deselect",
            width: 40,
            sortable: false,
            renderHeader: () => (
              <IconButton onClick={handleDeselect} style={{ color: 'white' }}>
                <CloseIcon />
              </IconButton>
            ),
            renderCell: (params) => (
              <Radio checked={radioChecked[0] === params.id} value={params.id} style={{ color: 'white' }} />
            ),
            sortable: false,
          },
        {field: "Date",headerName:"SELECT RECORDING",flex:1,},
      

        
    ];

    /////////Create Data Rows///////////

    const recordingRows=[];

    var idCounter=0;
    const createRow = (data) => {
        idCounter += 1;

        return { id: idCounter, Date:data };
      };
    
    
    ////// UseStates for the EmailAdress and HeatmapWidth////////
    const [emailAddr, setEmailAddr] = useState();
    const [heatmap_width, setHeatmapWidth]=useState("100%");
    
   
    ///////////////Convert isoDates to custom date string////////////////////
    function convertToCustomFormat(isoDateString) {
        const isoDate = new Date(isoDateString);
        
        const year = isoDate.getFullYear();
        const month = String(isoDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(isoDate.getDate()).padStart(2, '0');
        const hours = String(isoDate.getHours()).padStart(2, '0');
        const minutes = String(isoDate.getMinutes()).padStart(2, '0');
        const seconds = String(isoDate.getSeconds()).padStart(2, '0');
      
        const customFormatString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return customFormatString;
      };
      
    
    ///////////Group Recordings for Data Panel/////////////
    

    function groupRecordings(datetimeStrings,dispData) {
        // Convert datetime strings to Date objects
        const dates = datetimeStrings.map(datetimeString => new Date(datetimeString));
      
        // Sort dates in ascending order
        dates.sort((a, b) => a - b);
      
        // Group dates into recordings with a maximum time difference of 60 seconds
        const recordings = [];
        const groupDispData=[];
        let currentRecording = [dates[0]];
        let currentDispDataRecording =[dispData[0]];
      
        for (let i = 1; i < dates.length; i++) {
          const timeDifference = (dates[i] - dates[i - 1]) / 1000; // in seconds
      
          if (timeDifference <= 60) {
            // Add to the current recording
            currentRecording.push(dates[i]);
            currentDispDataRecording.push(dispData[i]);
          } else {
            // Start a new recording
            recordings.push(currentRecording);
            groupDispData.push(currentDispDataRecording);


            currentRecording = [dates[i]];
            currentDispDataRecording=[dispData[i]];

          }
          
        }
        
        // Add the last recording
        recordings.push(currentRecording);
        groupDispData.push(currentDispDataRecording);
        for (let i = 0; i < recordings.length; i++) {
            recordings[i]=recordings[i].map(element=>convertToCustomFormat(element.toISOString()));
        }
        
        return [groupDispData,recordings];
    }
    ///////////////////////////////////////////////////
    
    var datetimes = [];
    
    dispData.forEach(element => {
        datetimes.push(element["Date"]);
    });
    
    const result=groupRecordings(datetimes,dispData);
    const groupDispData=result[0];
    // console.log("GroupDisp:", groupDispData)
    const recordings=result[1];

    recordings.forEach(element=>recordingRows.push(createRow(element[0].concat(" - ".concat(element[element.length-1])))));


    let radioChecked =[null];// [recordingRows[0].id];
    
    const [selectionModel, setSelectionModel] = React.useState(radioChecked);
    radioChecked = selectionModel;


    const selectedRow = recordingRows.filter((item) => {
        return item.id === selectionModel[0];
    });
    var Data=(radioChecked[0]!=null)?(groupDispData[radioChecked[0]-1]):(dispData);
    var hideFooterBool=(Data.length)<=100?true:false;

    // //Automatic heatmap resizing attempt
    // React.useEffect(() => {
    //     const debouncedHandleResize = debounce(function handleResize() {
    //       setHeatmapWidth("80%");
    //     }, 1000);
    
    //     window.addEventListener("resize", debouncedHandleResize);
    //     return _ => {
    //       window.removeEventListener("resize", debouncedHandleResize);
    //     };
    //   });
      
    
    // function debounce(fn, ms) {
    //   let timer;
    //   return _ => {
    //     clearTimeout(timer);
    //     timer = setTimeout(_ => {
    //       timer = null;
    //       fn.apply(this, arguments);
    //     }, ms);
    //   };
    // }

    //get size of component
    // const ref = useRef(null);
    // const [width, setWidth] = useState(0);
    // const [height, setHeight] = useState(0);

    // useLayoutEffect(() => {
    //     setWidth(ref.current.offsetWidth);
    //     setHeight(ref.current.offsetHeight);
    // }, []);




    ////////get data for waterfall plot and filter via radiobutton////////////////////////

   

    const get_heatmap_data = async function(){
        var value=dispData.slice();
        // console.log("DispData:", value)
        // make an array of fft dates for the query
        var tt_utc_array = [];
  
        value.forEach(element => {
            tt_utc_array.push(element["Date"]);
        });

        var midfrequency = [];
        value.forEach(element => {
            midfrequency.push(element["MidFreq"]);
        });
        
        setLoading(true);
        // setLoading2(true);

 
        const spectral_data = (await (await fetch('/api/psds', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({utcs: tt_utc_array}) // body data type must match "Content-Type" header
        })).json())["result"];

    

        value.forEach(element => {
            let fft_obj = spectral_data.find(fft => fft["fft_time_utc"] === element["Date"]);
            let psd_array = generatePSDArray(fft_obj);
            element.psd = psd_array;
        });
        const data_array=value.map((item)=>item.psd);
        
        setHeatmapData(data_array);
        var data_array_filtered=[];
        var date_array=[];
        // console.log(radioChecked[0]);
        if (radioChecked[0]==null){
            data_array_filtered=data_array.slice();
            date_array=datetimes.slice();
        }
        else{
            data_array_filtered=data_array.filter(element => element.date===(groupDispData[radioChecked[0]-1].find(x => x.date===element.date)).date);
            date_array=recordings[radioChecked[0]-1];
        }
        
        var date_array1=date_array.slice();
        date_array1.reverse();
        var data_array_filtered1=data_array_filtered.slice();
        setHeatmapDataFiltered(data_array_filtered1.reverse());
        //console.log("Heatmap Data Filtered:", data_array_filtered1.reverse());

        
        setHeatmapDates(date_array1);
        
        setMidFrequency(midfrequency[0]);
        setLoading(false); // Mark loading as complete
     
    }
    //TODO: check order of heatmap rows (reverse)
    useEffect(() => {
        get_heatmap_data();
      }, []);
    
      //////////////////////////////////////////////////////////////////////


/////////////Filter heatmap data(waterfall plot)//////////////////////////////


    var freq=range(midFrequency-5,midFrequency+5,4095);


    function filterHeatmapData() {
        if (heatmapData) {
            setLoading(true);
    
            let heatmap_data_filtered = [];
            let date_array_filtered = [];
            if (radioChecked[0] != null && groupDispData[radioChecked[0] - 1]) {
                heatmap_data_filtered = groupDispData[radioChecked[0] - 1].map((item) => item.psd);
                date_array_filtered = groupDispData[radioChecked[0] - 1].map((item) => item.Date);
            } else {
                heatmap_data_filtered = [];
                date_array_filtered = []; 
            }
            //console.log("Aktualisierte Heatmap-Daten:", heatmap_data_filtered);
            // console.log("Datumsangaben:", date_array_filtered);

            setHeatmapDataFiltered(heatmap_data_filtered);
            setDateArrayFiltered(date_array_filtered);
            setLoading(false);
        }
    }
   
    useEffect(()=>{
        filterHeatmapData();
    },[selectionModel])

    //useEffect(() => {
      //  console.log("Aktualisierte Heatmap Data Filtered:", heatmapDataFiltered);
        //console.log("Aktualisiertes Date Array:", date_array);
    //}, [heatmapDataFiltered, date_array]);
    
    //////////////////////////////////////////////////////////////////////

    //Prepare Data for Download///////////////

    
    // callback for the download button
    const get = async function (event, value, emailAddr) {
        
        const selectedDate = selectedRow.length > 0 ? selectedRow[0].Date : null;
        var data_array_filtered=groupDispData[radioChecked[0]-1];

        const customFileName = `fft_data_${selectedDate}.csv`;
        
            downloadFFTData(data_array_filtered, customFileName);
            const emailDetails = {
                userEmail: emailAddr,
                selectedData: selectedDate
            };
        
            fetch('/api/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailDetails),
            })
            .then(response => response.json())
            .then(data => console.log('Sending E-Mail successful', data))
            .catch((error) => {
                console.error('Error while sending E-Mail:', error);
            });
            value=dispData.slice();
        // make an array of fft dates for the query
        var tt_utc_array = [];
        value.forEach(element => {
            tt_utc_array.push(element["Date"]);
        });
        
        const band = value[0]["Band"];
        const MidFreq = value[0]["MidFreq"];
        const firstDate = tt_utc_array[0];
        const lastDate = tt_utc_array.slice(-1)[0];     // this is the way to get last element
        const downloadTimestamp = new Date().toISOString().slice(0, 19).replace('T', ' ');
        const downloadRequest = {
            timestamp: downloadTimestamp,
            email: emailAddr,
            startDate: firstDate,
            endDate: lastDate,
            band: band,
            middleFreq: MidFreq
        };
        ///////////////////////////////////////////

        
        //////////////// Sending Download Request to the server /////////////////////////

        // const res = (await (await fetch('/api/downloadrequests', {
        //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(downloadRequest) // body data type must match "Content-Type" header
        // })).json())["result"];

        

        // const data = (await (await fetch('/api/psds', {
        //   method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify({utcs: tt_utc_array}) // body data type must match "Content-Type" header
        // })).json())["result"];


        // // insert data into the object
        // value.forEach(element => {
        //   let fft_obj = data.find(fft => fft["fft_time_utc"] === element["Date"]);
        //   let psd_array = generatePSDArray(fft_obj);
        //   element.psd = psd_array;
        // });


        
        // const csvdata = csvmaker(value);
        // download(csvdata);
    };

    ///////////////////////////////////////Rendering/////////////////////////////////////////////

    return (
        <div className="data-panel" >
            <center>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2 style={{ margin: 0 }}>Selected Data:</h2>
                    <IconButton aria-label='close' onClick={handleCloseButtonClick} color='primary'>
                        <CloseIcon />
                    </IconButton>
                </div>
            </center>
            <Grid container spacing={2} alignItems="stretch">
                
            <Grid item xs={4}>
    <div class="grid">
        <DataGrid
            rows={recordingRows}
            columns={recordingColumns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 5,
                    },
                },
            }}
            getRowHeight={() => 'auto'}
            headerHeight={50}
            footerHeight={50}
            pageSizeOptions={[5]}
            disableColumnMenu={true}
            disableRowSelectionOnClick
            selectionModel={selectionModel}
            hideFooter={true}
            onSelectionModelChange={(newSelectionModel) => {
                if (!loading) { // Erlauben Sie die Auswahl nur, wenn die Daten geladen sind
                    setSelectionModel(newSelectionModel);
                    if (newSelectionModel.length > 0) {
                        radioChecked = [newSelectionModel[0]];
                    } else {
                        radioChecked = [null];
                    }
                    filterHeatmapData();
                }
            }}
            loading={loading} // Verwenden Sie den 'loading'-Status, um die Interaktion mit DataGrid zu steuern
            sx={{
                fontSize:"100%",
                color: "#ffffff",
                ".MuiTablePagination-toolbar": {
                    color: "#ffffff"
                }
            }}
        /> 
    </div>
</Grid>
                
                <Grid item xs={8}>
                <div class="grid">
                <DataGrid 
                            // ref={ref}
                            columns={columns}
                            rows={Data}
                            getRowHeight={() => 'auto'}
                            headerHeight={50}
                            footerHeight={50}
                            pageSizeOptions={[100]}
                            hideFooter={hideFooterBool}
                            
                            sx={{
                                fontSize:"100%",
                                color: "#ffffff",
                                ".MuiTablePagination-toolbar": {
                                    color: "#ffffff"
                                }
                            }}
                        />
                
                </div>
                </Grid>
                
            </Grid>
                
                
            
            {loading ? (
    <div>
        {/* ...Loading-Zustand... */}
        <center>
            <br />
            <br />
            <img src={spinner} alt="loading..." width="20%" height="20%"/>
            <p>LOADING WATERFALL PLOT...</p>
        </center>

    </div>
) : selectionModel && selectionModel.length > 0 ? (
    
        <div >
        <Plot
            className="heatmap"
            id="heatmapPlot"
            
            data={[
                {
                    x: freq,
                    y: dateArrayFiltered,
                    z: heatmapDataFiltered,
                    type: 'heatmap',
                }
            ]}
            //style={{ paddingTop:"1%", width:"100%", height:"50%" }}
            layout={{
                margin: { t: 10, r: 15, b: 25, l: 40 },
                plot_bgcolor: "rgba(0,0,0,0)",
                paper_bgcolor:"rgba(0,0,0,0)",
                font: { color: "white" },
                yaxis: {
                    title: { text: 'Time', standoff: 10 },
                    tickformat: "%H:%M:%S", 
                    tickmode: 'array',
                    automargin: true,
                    showticklabels: true 
                  },
                xaxis: { title: { text: 'Frequency [MHz]', standoff: 10 }, automargin: true },
            }}
        />
    </div>
) : (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <h2>Please Select Recording</h2>
    </div>
)}

             
            <p align="right">
                {/* <Button value="Download Data" onClick={(e) => {get(e, dispData)}}>Download Data</Button>*/}
                <div class="download_button">
                    <Button  value="Download Data" onClick={handleModalOpen}>Download Data</Button>
                </div>
                <Modal
                    open={ModalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Download SALSAT Data
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 3, mb: 4 }}>
                            Please enter you E-mail address.
                        </Typography>
                        <TextField
                            required
                            error={!isEmailValid(emailAddr)}
                            onChange={event => setEmailAddr(event.target.value)}
                            id="outlined-required"
                            label="Required"
                        />
                        <Button
                            onClick={(event) => {
                                get(event, dispData, emailAddr);
                                handleModalClose();
                            }}
                            disabled={!isEmailValid(emailAddr)}
                            value="Submit">Submit</Button>
                    </Box>
                </Modal>
            </p>
            {/* </center> */}
        </div>
    );
};

export default React.memo(DataPanel);