import React, { useState, useRef } from 'react';
import {
    Modal,
    Box,
    TextField,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Button,
    Typography,
    FormControl,
    IconButton,
} from '@mui/material/';
import CloseIcon from '@mui/icons-material/Close';
import { isEmailValid } from './helperFunctions.js';
import ReCAPTCHA from "react-google-recaptcha";

const RequestSpectralDataModal = (props) => {
    // State variables for form data
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [frequencyBand, setFrequencyBand] = useState('UHF');
    const [latitude, setLatitude] = useState('');
    const [middleFrequency, setMiddleFrequency] = useState('');
    const [longitude, setLongitude] = useState('');
    const [latDirection, setLatDirection] = useState('North');
    const [longDirection, setLongDirection] = useState('West');

    const recaptcha = useRef();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // First, check if the email is valid
        if (!isEmailValid(email)) {
            alert('Please enter a valid email address');
            return;
        }
    
        // Prepare data for sending
        const dataToSend = {
            email,
            comment,
            frequencyBand,
            middleFrequency,
            latitude,
            longitude,
            latDirection,
            longDirection
        };
    
        // Retrieve the reCAPTCHA token
const token = recaptcha.current.getValue();

try {
    const response = await fetch('https://salsat.raumfahrttechnik.tu-berlin.de/api/submit', {
        method: 'POST',
        body: JSON.stringify({ data: dataToSend, recaptchaToken: token }),
        headers: {
            'Content-Type': 'application/json',
        },
        
    });

    if (response.ok) {
        console.log('Sending Data Request Successful');
        alert('Sending Data Request Successful');
    } else {
        console.error('Error while sending Data Request: ', response.status);
        alert('Error while sending Data Request: ' + response.status);
    }
} catch (error) {
    console.error('Error:', error);
    alert('Error while sending Data Request: ' + error.message);
}

// Reset the reCAPTCHA after the request
recaptcha.current.reset();

    };
    
    
    // Modal styling
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight: '90vh',
        overflowY: 'auto',
    };

    return (
        <Modal
            open={props.ModalOpen}
            onClose={props.onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={modalStyle}>
                {/* Close button */}
                <IconButton
                    aria-label='close'
                    onClick={props.onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                
                {/* Modal Title */}
                <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                    Spectral Data Request
                </Typography>
                
                {/* Frequency band selection */}
                <FormControl component="fieldset" sx={{ mb: 2 }}>
                    <FormLabel component="legend">Frequency Band</FormLabel>
                    <RadioGroup row value={frequencyBand} onChange={(e) => setFrequencyBand(e.target.value)}>
                        <FormControlLabel value="UHF" control={<Radio />} label="UHF" />
                        <FormControlLabel value="VHF" control={<Radio />} label="VHF" />
                        <FormControlLabel value="S-Band" control={<Radio />} label="S-Band" />
                    </RadioGroup>
                </FormControl>

                {/* Middle frequency input */}
                <TextField
                    label="Middle Frequency (MHz)"
                    type="number"
                    fullWidth
                    value={middleFrequency}
                    onChange={(e) => setMiddleFrequency(e.target.value)}
                    sx={{ mb: 2 }}
                />

                {/* Geographic coordinates input */}
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Latitude (°)"
                            type="number"
                            fullWidth
                            value={latitude}
                            onChange={(e) => setLatitude(e.target.value)}
                        />
                        <RadioGroup
                            row
                            aria-labelledby="latitude-direction-label"
                            name="latitude-direction"
                            value={latDirection}
                            onChange={(e) => setLatDirection(e.target.value)}
                            sx={{ mt: 1 }}
                        >
                            <FormControlLabel value="North" control={<Radio />} label="North" />
                            <FormControlLabel value="South" control={<Radio />} label="South" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Longitude (°)"
                            type="number"
                            fullWidth
                            value={longitude}
                            onChange={(e) => setLongitude(e.target.value)}
                        />
                        <RadioGroup
                            row
                            aria-labelledby="longitude-direction-label"
                            name="longitude-direction"
                            value={longDirection}
                            onChange={(e) => setLongDirection(e.target.value)}
                            sx={{ mt: 1 }}
                        >
                            <FormControlLabel value="West" control={<Radio />} label="West" />
                            <FormControlLabel value="East" control={<Radio />} label="East" />
                        </RadioGroup>
                    </Grid>
                </Grid>

                {/* Email input */}
                <TextField
                    label="E-mail Address"
                    type="email"
                    fullWidth
                    required
                    error={!isEmailValid(email)}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ mb: 2 }}
                />

                {/* Comment input */}
                <TextField
                    label="Comment"
                    multiline
                    rows={4}
                    fullWidth
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    sx={{ mb: 2 }}
                />

                {/* Submit and close buttons */}
                <form> 
                    <ReCAPTCHA 
                    sitekey='6Le7OIspAAAAAKbs5qySVqA_c7VHxZGordulo6Ud'
                    ref={recaptcha} />
                <Button variant="contained" onClick={handleSubmit} sx={{ mt: 1, mr: 1 }}>
                    Submit
                </Button></form>
                <Button variant="outlined" onClick={props.onClose} sx={{ mt: 1 }}>
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default React.memo(RequestSpectralDataModal);