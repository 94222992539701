import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

var heatmap_data = [];
const rootElement = document.getElementById("root");

const startAPP = async () => {
    try {
        ReactDOM.render(<App data={heatmap_data} />, rootElement);
    } catch (error) {
        console.log(error);
    }
};

startAPP();
